var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "el-loading--maia",
      attrs: { id: "bottomMode", "element-loading-text": _vm.loadingTxt }
    },
    [
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 24 } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading_left,
                    expression: "loading_left"
                  }
                ],
                staticClass: "el-loading--maia left",
                class: { turnList_l: _vm.btnDirection },
                attrs: { "element-loading-text": "问题列表获取中..." }
              },
              [
                _c(
                  "div",
                  { staticClass: "title" },
                  [
                    _c("span", [
                      _c("i", {
                        staticClass: "el-icon-back",
                        attrs: { title: "返回问题管理" },
                        on: { click: _vm.reback }
                      }),
                      _vm._v(" 问题列表 "),
                      _vm.page.totalCount > 0
                        ? _c("span", [
                            _vm._v("(" + _vm._s(_vm.page.totalCount) + ")")
                          ])
                        : _vm._e()
                    ]),
                    _c(
                      "el-popover",
                      {
                        ref: "shaixuanPopover",
                        attrs: {
                          trigger: "click",
                          placement: "bottom-end",
                          "popper-class": "shaixuanPopover"
                        }
                      },
                      [
                        _c("img", {
                          attrs: {
                            slot: "reference",
                            src: require("./image/shaixuan.png"),
                            alt: ""
                          },
                          slot: "reference"
                        }),
                        _c(
                          "div",
                          { staticClass: "selectForm" },
                          [
                            _c(
                              "el-form",
                              {
                                ref: "form",
                                attrs: {
                                  model: _vm.selectForm,
                                  size: "small",
                                  "label-width": "80px"
                                }
                              },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "阶段:" } },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticClass:
                                          "el-select--maia select-small",
                                        attrs: {
                                          clearable: "",
                                          placeholder: "文件夹"
                                        },
                                        on: { change: _vm.stageChange },
                                        model: {
                                          value: _vm.selectForm.stage,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.selectForm,
                                              "stage",
                                              $$v
                                            )
                                          },
                                          expression: "selectForm.stage"
                                        }
                                      },
                                      _vm._l(_vm.options.folderList, function(
                                        item,
                                        index
                                      ) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.folderName,
                                            value: item.folderId
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                ),
                                !!_vm.selectForm.stage
                                  ? _c(
                                      "el-form-item",
                                      { attrs: { label: "版本:" } },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              clearable: "",
                                              placeholder: "版本"
                                            },
                                            model: {
                                              value: _vm.selectForm.version,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.selectForm,
                                                  "version",
                                                  $$v
                                                )
                                              },
                                              expression: "selectForm.version"
                                            }
                                          },
                                          _vm._l(
                                            _vm.options.versionList,
                                            function(item) {
                                              return _c("el-option", {
                                                key: item.versionID,
                                                attrs: {
                                                  label: item.versionName,
                                                  value: item.versionID
                                                }
                                              })
                                            }
                                          ),
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "专业:" } },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          multiple: "",
                                          "collapse-tags": "",
                                          clearable: "",
                                          placeholder: "专业"
                                        },
                                        model: {
                                          value: _vm.selectForm.specIdList,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.selectForm,
                                              "specIdList",
                                              $$v
                                            )
                                          },
                                          expression: "selectForm.specIdList"
                                        }
                                      },
                                      _vm._l(
                                        _vm.selectDic.prjSpecList,
                                        function(item) {
                                          return _c("el-option", {
                                            key: item.specId,
                                            attrs: {
                                              label: item.specName,
                                              value: item.specId
                                            }
                                          })
                                        }
                                      ),
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "问题类型:" } },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          multiple: "",
                                          "collapse-tags": "",
                                          clearable: "",
                                          placeholder: "问题类型"
                                        },
                                        model: {
                                          value: _vm.selectForm.queTypeIdList,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.selectForm,
                                              "queTypeIdList",
                                              $$v
                                            )
                                          },
                                          expression: "selectForm.queTypeIdList"
                                        }
                                      },
                                      _vm._l(_vm.selectDic.typeList, function(
                                        item
                                      ) {
                                        return _c("el-option", {
                                          key: item.id,
                                          attrs: {
                                            label: item.cnname,
                                            value: item.id
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "审查人:" } },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          multiple: "",
                                          "collapse-tags": "",
                                          clearable: "",
                                          placeholder: "审查人"
                                        },
                                        model: {
                                          value:
                                            _vm.selectForm.createUserIdList,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.selectForm,
                                              "createUserIdList",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "selectForm.createUserIdList"
                                        }
                                      },
                                      _vm._l(_vm.selectDic.userVoList, function(
                                        item
                                      ) {
                                        return _c("el-option", {
                                          key: item.otherId,
                                          attrs: {
                                            label: item.otherName,
                                            value: item.otherId
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "selecBottom" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "maiaPlain", size: "small" },
                                on: { click: _vm.resetSelectData }
                              },
                              [_vm._v("重 置")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "maiaPrimary", size: "small" },
                                on: { click: _vm.sureSelectData }
                              },
                              [_vm._v("查 询")]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "list-wrapper list" }, [
                  _c(
                    "ul",
                    {
                      directives: [
                        {
                          name: "infinite-scroll",
                          rawName: "v-infinite-scroll",
                          value: _vm.loadMore,
                          expression: "loadMore"
                        }
                      ],
                      attrs: {
                        "infinite-scroll-disabled": "disabled",
                        "infinite-scroll-distance": "10",
                        "infinite-scroll-immediate": "false"
                      }
                    },
                    _vm._l(_vm.allIssueList, function(item) {
                      return _c(
                        "li",
                        {
                          key: item.qMID,
                          staticClass: "list-item",
                          class: { active: _vm.activeId == item.qMID },
                          on: {
                            click: function($event) {
                              return _vm.openDetail(item.qMID)
                            }
                          }
                        },
                        [
                          _c("p", [
                            _c(
                              "span",
                              {
                                staticClass: "statusAll",
                                class: "status" + item.queStatusID
                              },
                              [_vm._v("【" + _vm._s(item.queStatus) + "】")]
                            ),
                            _vm._v(" " + _vm._s(item.queTypeName) + " "),
                            _c("span", { staticClass: "rightOrder" }, [
                              _vm._v(_vm._s(item.labelName))
                            ])
                          ]),
                          _c(
                            "el-form",
                            {
                              staticClass: "itemForm",
                              attrs: { inline: "", "label-position": "right" }
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "状态:" } },
                                [_vm._v(" " + _vm._s(item.queStatus) + " ")]
                              ),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "labelPo",
                                  attrs: { label: "修改方:" }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(item.modifyPartyName) + " "
                                  )
                                ]
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "专业:" } },
                                [_vm._v(" " + _vm._s(item.specName) + " ")]
                              ),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "labelPo",
                                  attrs: { label: "完成时间:" }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(item.requireFinishTime) + " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _vm.listLoading
                    ? _c("p", { staticClass: "bottomInfo" }, [
                        _vm._v("加载中...")
                      ])
                    : _vm._e(),
                  _vm.noMore
                    ? _c("p", { staticClass: "bottomInfo" }, [
                        _vm._v("没有更多了")
                      ])
                    : _vm._e()
                ])
              ]
            ),
            _c(
              "div",
              {
                staticClass: "btn",
                class: { btnMove: _vm.btnDirection },
                on: { click: _vm.showList }
              },
              [
                _c("i", {
                  class: _vm.btnDirection
                    ? "el-icon-arrow-right"
                    : "el-icon-arrow-left"
                })
              ]
            ),
            _vm.allIssueList.length > 0
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading_right,
                        expression: "loading_right"
                      }
                    ],
                    staticClass: "el-loading--maia right",
                    class: { turnList_r: _vm.btnDirection },
                    attrs: { "element-loading-text": _vm.rightTxt }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "title" },
                      [
                        _vm.isNewList &&
                        !(_vm.readItemData.fileID || "").includes(",")
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function($event) {
                                    return _vm.openFile(_vm.readItemData)
                                  }
                                }
                              },
                              [_vm._v("打开文件")]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm.isNewList
                      ? _c(
                          "div",
                          { staticClass: "imgList" },
                          [
                            _c(
                              "viewer",
                              {
                                staticStyle: { height: "100%" },
                                attrs: { images: _vm.readItemData.urlList }
                              },
                              _vm._l(_vm.readItemData.urlList, function(
                                img,
                                index
                              ) {
                                return _c("img", {
                                  key: index,
                                  staticClass: "short-img",
                                  attrs: { src: img }
                                })
                              }),
                              0
                            )
                          ],
                          1
                        )
                      : _c(
                          "div",
                          { staticClass: "imgList" },
                          [
                            _c(
                              "viewer",
                              {
                                staticStyle: { height: "100%" },
                                attrs: { images: _vm.readItemData.imgList }
                              },
                              _vm._l(_vm.readItemData.imgList, function(
                                img,
                                index
                              ) {
                                return _c("img", {
                                  key: index,
                                  staticClass: "short-img",
                                  attrs: { src: img.url }
                                })
                              }),
                              0
                            )
                          ],
                          1
                        ),
                    _c("div", { staticClass: "operDetail" }, [
                      _c(
                        "div",
                        { staticClass: "nav" },
                        [
                          _c(
                            "el-tabs",
                            {
                              staticClass: "el-tabs--maiaUnderline",
                              on: { "tab-click": _vm.detailClick },
                              model: {
                                value: _vm.activeNav,
                                callback: function($$v) {
                                  _vm.activeNav = $$v
                                },
                                expression: "activeNav"
                              }
                            },
                            _vm._l(_vm.detailList, function(item, index) {
                              return _c("el-tab-pane", {
                                key: index,
                                attrs: { label: item, name: index + "" }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "content" },
                        [
                          _vm.activeNav == "0"
                            ? _c(
                                "div",
                                { staticClass: "detail" },
                                [
                                  _c(
                                    "el-form",
                                    {
                                      staticClass: "detailShow",
                                      attrs: {
                                        inline: "",
                                        "label-width": "100px"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "问题状态:" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.readItemData.queStatus
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "专业:" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.readItemData.specName
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "跨专业:" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.readItemData.multiSpec
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "所属空间:" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                (_vm.readItemData.roomName ||
                                                  "") +
                                                  " " +
                                                  (_vm.readItemData
                                                    .roomNumber || "")
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "所属构件:" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.readItemData.categoryName
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "问题类型:" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.readItemData.queTypeName
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "问题等级:" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.readItemData.queLevel
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "修改方:" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.readItemData.modifyPartyName
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "要求完成时间:" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.readItemData
                                                  .requireFinishTime
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          staticClass: "queDesc",
                                          attrs: { label: "问题描述:" }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.readItemData.queDesc) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.isNewList
                                    ? _c(
                                        "div",
                                        { staticClass: "queStatusBtn" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "dealData" },
                                            [
                                              _vm._l(
                                                _vm.dicArray.statusList,
                                                function(item) {
                                                  return _c(
                                                    "el-button",
                                                    {
                                                      key: item.id,
                                                      attrs: {
                                                        type: "maiaPlain",
                                                        size: "small"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.changeStatus(
                                                            item
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.cnname)
                                                      )
                                                    ]
                                                  )
                                                }
                                              ),
                                              _c(
                                                "el-button",
                                                {
                                                  key: "000",
                                                  attrs: {
                                                    utton: "",
                                                    type: "maiaPlain",
                                                    size: "small",
                                                    disabled:
                                                      _vm.readItemData
                                                        .queStatusID != "22" ||
                                                      _vm.readItemData.isDel !=
                                                        0
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.changeStatus()
                                                    }
                                                  }
                                                },
                                                [_vm._v("待决策")]
                                              )
                                            ],
                                            2
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "auto-to-next" },
                                            [
                                              _c("el-checkbox", {
                                                attrs: {
                                                  label: "自动跳转到下一条问题"
                                                },
                                                model: {
                                                  value: _vm.autoToNext,
                                                  callback: function($$v) {
                                                    _vm.autoToNext = $$v
                                                  },
                                                  expression: "autoToNext"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm.activeNav == "1"
                            ? _c("reply", {
                                ref: "reply",
                                attrs: {
                                  type: "3D",
                                  isNewList: _vm.isNewList,
                                  id: _vm.activeId
                                },
                                on: { resetList: _vm.resetList }
                              })
                            : _vm.activeNav == "2"
                            ? _c("record", {
                                ref: "record",
                                attrs: {
                                  type: "3D",
                                  isNewList: _vm.isNewList,
                                  id: _vm.activeId
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ])
                  ]
                )
              : _c("div", { staticClass: "el-loading--maia right" })
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }